<template>
  <q-form ref="formEle" class="q-gutter-md">
    <template v-if="readonly">
      <q-field
        v-if="form.id"
        label="ID"
        :readonly="readonly"
        stack-label
        dense
        outlined
      >
        <span>{{form.id}}</span>
      </q-field>
    </template>
    <x-tree-select
      v-model="form.pid"
      :rules="rules.pid"
      label-slot
      node-key="id"
      label-key="configName"
      :nodes="parentNodes"
      map-options
      emit-value
      :readonly="readonly"
      dense
      filterable
      clearable
      outlined
      hide-bottom-space
    >
      <template v-slot:label>
        <span class="text-negative">*</span>
        <span>上级节点</span>
      </template>
    </x-tree-select>
    <q-input
      v-model="form.configCode"
      :rules="rules.configCode"
      label-slot
      :readonly="readonly"
      maxlength="64"
      dense
      clearable
      outlined
      hide-bottom-space
    >
      <template v-slot:label>
        <span class="text-negative">*</span>
        <span>配置编码</span>
      </template>
    </q-input>
    <q-input
      v-model="form.configName"
      :rules="rules.configName"
      label-slot
      :readonly="readonly"
      maxlength="64"
      dense
      clearable
      outlined
      hide-bottom-space
    >
      <template v-slot:label>
        <span class="text-negative">*</span>
        <span>配置名称</span>
      </template>
    </q-input>
    <q-select
      v-model="form.configType"
      :rules="rules.configType"
      label-slot
      :options="$dict.sysConfigType"
      map-options
      emit-value
      :readonly="readonly"
      dense
      clearable
      outlined
      hide-bottom-space
    >
      <template v-slot:label>
        <span class="text-negative">*</span>
        <span>配置类型</span>
      </template>
    </q-select>
    <q-input
      v-model="form.configContent"
      label="配置内容"
      type="textarea"
      :readonly="readonly"
      dense
      clearable
      outlined
      hide-bottom-space
    />
    <q-field
      v-model="form.enable"
      label="是否启用"
      :readonly="readonly"
      dense
      stack-label
      clearable
      outlined
      hide-bottom-space
    >
      <q-option-group
        v-model="form.enable"
        :options="$dict.enable"
        :disable="readonly"
        inline
      />
    </q-field>
    <q-input
      v-model="form.sortNum"
      :rules="rules.sortNum"
      label="排序号"
      :readonly="readonly"
      dense
      clearable
      outlined
      hide-bottom-space
    />
    <q-input
      v-model="form.remark"
      label="备注"
      type="textarea"
      :readonly="readonly"
      maxlength="255"
      dense
      clearable
      outlined
      hide-bottom-space
    />
    <template v-if="readonly">
      <q-field
        v-if="form.createTime"
        label="创建时间"
        :readonly="readonly"
        stack-label
        dense
        outlined
      >
        <span>{{form.createTime}}</span>
      </q-field>
      <q-field
        v-if="form.updateTime"
        label="更新时间"
        :readonly="readonly"
        stack-label
        dense
        outlined
      >
        <span>{{form.updateTime}}</span>
      </q-field>
    </template>
  </q-form>
</template>

<script>
import { computed, getCurrentInstance, nextTick, ref, watch } from 'vue'
import { XTreeSelect } from '../../../components/tree-select'
import { qNotBlank, qNotNull, qNumber } from '../../../utils/validate-utils'

export default {
  components: {
    XTreeSelect
  },
  props: {
    data: { type: Object },
    readonly: { type: Boolean, default: false },
    nodes: { type: Array, default: () => [] }
  },
  setup (props) {
    const { proxy } = getCurrentInstance()
    const form = ref(dataToForm(props.data))
    const rules = ref({
      pid: [qNotNull],
      configName: [qNotBlank],
      configCode: [qNotBlank],
      configType: [qNotNull],
      sortNum: [qNumber]
    })
    const parentNodes = computed(
      () => [{ id: '0', pid: null, configName: '根节点', children: props.nodes }])

    function dataToForm (data) {
      return Object.assign({
        pid: null,
        configName: null,
        configCode: null,
        configType: null,
        configContent: null,
        enable: true,
        sortNum: null,
        remark: null
      }, data)
    }

    async function validate () {
      return await proxy.$refs.formEle.validate()
    }

    async function resetValidation () {
      nextTick(() => proxy.$refs.formEle.resetValidation())
    }

    function getForm () {
      return Object.assign({}, form.value)
    }

    async function validateAndGetForm () {
      if (await validate()) {
        return getForm()
      }
      throw new Error('Form valid error')
    }

    watch(() => props.data, (value) => {
      form.value = dataToForm(value)
      resetValidation()
    })

    return {
      form,
      rules,
      parentNodes,
      validate,
      resetValidation,
      getForm,
      validateAndGetForm
    }
  }
}
</script>
