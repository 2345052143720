<template>
  <div class="x-split-layout" v-if="$q.screen.lt.sm">
    <slot name="before"/>
    <q-dialog
      :model-value="showDialog"
      @update:model-value="onUpdateShowDialog"
      persistent>
      <q-card style="width:90vw">
        <q-card-section class="row items-center">
          <div class="text-h6">{{dialogTitle}}</div>
          <q-space/>
          <q-btn icon="close" flat round dense v-close-popup/>
        </q-card-section>
        <q-separator/>
        <q-card-section class="scroll" style="max-height:60vh">
          <slot name="after"/>
        </q-card-section>
        <q-separator/>
        <q-card-section>
          <div class="q-gutter-md text-right">
            <slot name="after-bottom"/>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
  <q-splitter v-else v-model="splitter">
    <template v-slot:before>
      <div class="q-pr-md">
        <slot name="before"/>
      </div>
    </template>
    <template v-slot:after>
      <div class="q-pl-md">
        <slot name="after"/>
        <div class="q-py-md q-gutter-md">
          <slot name="after-bottom"/>
        </div>
      </div>
    </template>
  </q-splitter>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'XSplitLayout',
  props: {
    dialogTitle: { type: String, default: '' },
    showDialog: { type: Boolean, default: false }
  },
  emits: [
    'update:show-dialog'
  ],
  setup (props, { emit }) {
    const splitter = ref(30)

    function onUpdateShowDialog (value) {
      emit('update:show-dialog', value)
    }

    return {
      splitter,
      onUpdateShowDialog
    }
  }
}
</script>
