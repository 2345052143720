import {
  computed,
  defineComponent,
  getCurrentInstance,
  h,
  nextTick,
  onBeforeUpdate,
  ref,
  vShow,
  watch,
  withDirectives
} from 'vue'

import QIcon from 'quasar/src/components/icon/QIcon.js'
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js'
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js'
import QSpinner from 'quasar/src/components/spinner/QSpinner.js'

import useDark, { useDarkProps } from 'quasar/src/composables/private/use-dark.js'

import { stopAndPrevent } from 'quasar/src/utils/event.js'
import { shouldIgnoreKey } from 'quasar/src/utils/private/key-composition.js'

export default defineComponent({
  name: 'XTree',

  props: {
    ...useDarkProps,

    nodes: {
      type: Array,
      required: true
    },
    nodeKey: {
      type: String,
      required: true
    },
    labelKey: {
      type: String,
      default: 'label'
    },
    childrenKey: {
      type: String,
      default: 'children'
    },

    color: String,
    controlColor: String,
    textColor: String,
    selectedColor: String,

    icon: String,

    tickStrategy: {
      type: String,
      default: 'none',
      validator: v => ['none', 'strict', 'leaf', 'leaf-filtered'].includes(v)
    },
    ticked: Array, // v-model:ticked
    expanded: Array, // v-model:expanded
    selected: {}, // v-model:selected

    defaultExpandAll: Boolean,
    accordion: Boolean,

    filter: String,
    filterMethod: Function,

    duration: Number,
    noConnectors: Boolean,

    noNodesLabel: String,
    noResultsLabel: String
  },

  emits: [
    'update:expanded',
    'update:ticked',
    'update:selected',
    'lazy-load',
    'after-show',
    'after-hide',
    'click'
  ],

  setup (props, { slots, emit }) {
    const { proxy } = getCurrentInstance()
    const { $q } = proxy

    const isDark = useDark(props, $q)

    const lazy = ref({})
    const innerTicked = ref(props.ticked || [])
    const innerExpanded = ref(props.expanded || [])

    let blurTargets = {}

    onBeforeUpdate(() => {
      blurTargets = {}
    })

    const classes = computed(() =>
      'x-tree q-tree' +
      (props.noConnectors === true ? ' q-tree--no-connectors' : '') +
      (isDark.value === true ? ' q-tree--dark' : '') +
      (props.color !== undefined ? ` text-${props.color}` : '')
    )

    const hasSelection = computed(() => props.selected !== undefined)

    const computedIcon = computed(() => props.icon || $q.iconSet.tree.icon)

    const computedControlColor = computed(() => props.controlColor || props.color)

    const textColorClass = computed(() => (
      props.textColor !== undefined
        ? ` text-${props.textColor}`
        : ''
    ))

    const selectedColorClass = computed(() => {
      const color = props.selectedColor || props.color
      return color ? ` text-${color}` : ''
    })

    const computedFilterMethod = computed(() => (
      props.filterMethod !== undefined
        ? props.filterMethod
        : (node, filter) => {
          const filt = filter.toLowerCase()
          return node[props.labelKey] &&
            node[props.labelKey].toLowerCase().indexOf(filt) > -1
        }
    ))

    const meta = computed(() => {
      const meta = {}

      const travel = (node, parent) => {
        const tickStrategy = node.tickStrategy || (parent ? parent.tickStrategy : props.tickStrategy)
        const
          key = node[props.nodeKey]
        const isParent = node[props.childrenKey] && node[props.childrenKey].length > 0
        const isLeaf = isParent !== true
        const selectable = node.disabled !== true && hasSelection.value === true && node.selectable !== false
        const expandable = node.disabled !== true && node.expandable !== false
        const hasTicking = tickStrategy !== 'none'
        const strictTicking = tickStrategy === 'strict'
        const leafFilteredTicking = tickStrategy === 'leaf-filtered'
        const leafTicking = tickStrategy === 'leaf' || tickStrategy === 'leaf-filtered'

        let tickable = node.disabled !== true && node.tickable !== false
        if (leafTicking === true && tickable === true && parent && parent.tickable !== true) {
          tickable = false
        }

        let localLazy = node.lazy
        if (
          localLazy === true &&
          lazy.value[key] !== undefined &&
          Array.isArray(node[props.childrenKey]) === true
        ) {
          localLazy = lazy.value[key]
        }

        const m = {
          key,
          parent,
          isParent,
          isLeaf,
          lazy: localLazy,
          disabled: node.disabled,
          link: node.disabled !== true && (selectable === true || (expandable === true && (isParent === true || localLazy === true))),
          children: [],
          matchesFilter: props.filter ? computedFilterMethod.value(node, props.filter) : true,

          selected: key === props.selected && selectable === true,
          selectable,
          expanded: isParent === true ? innerExpanded.value.includes(key) : false,
          expandable,
          noTick: node.noTick === true || (strictTicking !== true && localLazy && localLazy !== 'loaded'),
          tickable,
          tickStrategy,
          hasTicking,
          strictTicking,
          leafFilteredTicking,
          leafTicking,
          ticked: strictTicking === true
            ? innerTicked.value.includes(key)
            : (parent ? innerTicked.value.includes(key) || parent.ticked : innerTicked.value.includes(key))
        }

        meta[key] = m

        if (isParent === true) {
          m.children = node[props.childrenKey].map(n => travel(n, m))

          if (props.filter) {
            if (m.matchesFilter !== true) {
              m.matchesFilter = m.children.some(n => n.matchesFilter)
            } else if (
              m.noTick !== true &&
              m.disabled !== true &&
              m.tickable === true &&
              leafFilteredTicking === true &&
              m.children.every(n => n.matchesFilter !== true || n.noTick === true || n.tickable !== true) === true
            ) {
              m.tickable = false
            }
          }

          if (m.matchesFilter === true) {
            if (m.noTick !== true && strictTicking !== true && m.children.every(n => n.noTick) === true) {
              m.noTick = true
            }

            if (leafTicking) {
              m.indeterminate = m.children.some(node => node.indeterminate === true)
              m.tickable = m.tickable === true && m.children.some(node => node.tickable)

              if (m.indeterminate !== true) {
                const sel = m.children
                  .reduce((acc, meta) => (meta.ticked === true ? acc + 1 : acc), 0)

                if (sel === m.children.length) {
                  m.ticked = true
                } else if (sel > 0) {
                  m.indeterminate = true
                }
              }

              if (m.indeterminate === true) {
                m.indeterminateNextState = m.children
                  .every(meta => meta.tickable !== true || meta.ticked !== true)
              }
            }
          }
        }

        return m
      }

      props.nodes.forEach(node => travel(node, null))
      return meta
    })

    watch(() => props.ticked, val => {
      innerTicked.value = val
    })

    watch(() => props.expanded, val => {
      innerExpanded.value = val
    })

    function getNodeByKey (key) {
      const reduce = [].reduce

      const find = (result, node) => {
        if (result || !node) {
          return result
        }
        if (Array.isArray(node) === true) {
          return reduce.call(Object(node), find, result)
        }
        if (node[props.nodeKey] === key) {
          return node
        }
        if (node[props.childrenKey]) {
          return find(null, node[props.childrenKey])
        }
      }

      return find(null, props.nodes)
    }

    function getTickedNodes () {
      return innerTicked.value.map(key => getNodeByKey(key))
    }

    function getExpandedNodes () {
      return innerExpanded.value.map(key => getNodeByKey(key))
    }

    function isExpanded (key) {
      return key && meta.value[key]
        ? meta.value[key].expanded
        : false
    }

    function collapseAll () {
      if (props.expanded !== undefined) {
        emit('update:expanded', [])
      } else {
        innerExpanded.value = []
      }
    }

    function expandAll () {
      const
        expanded = innerExpanded.value
      const travel = node => {
        if (node[props.childrenKey] && node[props.childrenKey].length > 0) {
          if (node.expandable !== false && node.disabled !== true) {
            expanded.push(node[props.nodeKey])
            node[props.childrenKey].forEach(travel)
          }
        }
      }

      props.nodes.forEach(travel)

      if (props.expanded !== undefined) {
        emit('update:expanded', expanded)
      } else {
        innerExpanded.value = expanded
      }
    }

    function setExpanded (key, state, node = getNodeByKey(key), m = meta.value[key]) {
      if (m.lazy && m.lazy !== 'loaded') {
        if (m.lazy === 'loading') {
          return
        }

        lazy.value[key] = 'loading'
        if (Array.isArray(node[props.childrenKey]) !== true) {
          node[props.childrenKey] = []
        }
        emit('lazy-load', {
          node,
          key,
          done: children => {
            lazy.value[key] = 'loaded'
            node[props.childrenKey] = Array.isArray(children) === true ? children : []
            nextTick(() => {
              const localMeta = meta.value[key]
              if (localMeta && localMeta.isParent === true) {
                localSetExpanded(key, true)
              }
            })
          },
          fail: () => {
            delete lazy.value[key]
            if (node[props.childrenKey].length === 0) {
              delete node[props.childrenKey]
            }
          }
        })
      } else if (m.isParent === true && m.expandable === true) {
        localSetExpanded(key, state)
      }
    }

    function localSetExpanded (key, state) {
      let target = innerExpanded.value
      const shouldEmit = props.expanded !== undefined

      if (shouldEmit === true) {
        target = target.slice()
      }

      if (state) {
        if (props.accordion) {
          if (meta.value[key]) {
            const collapse = []
            if (meta.value[key].parent) {
              meta.value[key].parent.children.forEach(m => {
                if (m.key !== key && m.expandable === true) {
                  collapse.push(m.key)
                }
              })
            } else {
              props.nodes.forEach(node => {
                const k = node[props.nodeKey]
                if (k !== key) {
                  collapse.push(k)
                }
              })
            }
            if (collapse.length > 0) {
              target = target.filter(k => collapse.includes(k) === false)
            }
          }
        }

        target = target.concat([key])
          .filter((key, index, self) => self.indexOf(key) === index)
      } else {
        target = target.filter(k => k !== key)
      }

      if (shouldEmit === true) {
        emit('update:expanded', target)
      } else {
        innerExpanded.value = target
      }
    }

    function isTicked (key) {
      return key && meta.value[key]
        ? meta.value[key].ticked
        : false
    }

    function setTicked (keys, state) {
      let target = innerTicked.value
      const shouldEmit = props.ticked !== undefined

      if (shouldEmit === true) {
        target = target.slice()
      }

      if (state) {
        target = target.concat(keys)
          .filter((key, index, self) => self.indexOf(key) === index)
      } else {
        target = target.filter(k => keys.includes(k) === false)
      }

      if (shouldEmit === true) {
        emit('update:ticked', target)
      }
    }

    function getSlotScope (node, meta, key) {
      const scope = { tree: proxy, node, key, color: props.color, dark: isDark.value }

      Object.defineProperty(scope, 'expanded', {
        get: () => { return meta.expanded },
        set: val => { val !== meta.expanded && setExpanded(key, val) },
        configurable: true,
        enumerable: true
      })
      Object.defineProperty(scope, 'ticked', {
        get: () => { return meta.ticked },
        set: val => { val !== meta.ticked && setTicked([key], val) },
        configurable: true,
        enumerable: true
      })

      return scope
    }

    function getChildren (nodes) {
      return (
        props.filter
          ? nodes.filter(n => meta.value[n[props.nodeKey]].matchesFilter)
          : nodes
      ).map(child => getNode(child))
    }

    function getNodeMedia (node) {
      if (node.icon !== undefined) {
        return h(QIcon, {
          class: 'q-tree__icon q-mr-sm',
          name: node.icon,
          color: node.iconColor
        })
      }
      const src = node.img || node.avatar
      if (src) {
        return h('img', {
          class: `q-tree__${node.img ? 'img' : 'avatar'} q-mr-sm`,
          src
        })
      }
    }

    function onShow () {
      emit('after-show')
    }

    function onHide () {
      emit('after-hide')
    }

    function getNode (node) {
      const
        key = node[props.nodeKey]
      const m = meta.value[key]
      const header = node.header
        ? slots[`header-${node.header}`] || slots['default-header']
        : slots['default-header']

      const children = m.isParent === true
        ? getChildren(node[props.childrenKey])
        : []

      const isParent = children.length > 0 || (m.lazy && m.lazy !== 'loaded')

      let body = node.body
        ? slots[`body-${node.body}`] || slots['default-body']
        : slots['default-body']
      const slotScope = header !== undefined || body !== undefined
        ? getSlotScope(node, m, key)
        : null

      if (body !== undefined) {
        body = h('div', { class: 'q-tree__node-body relative-position' }, [
          h('div', { class: textColorClass.value }, [
            body(slotScope)
          ])
        ])
      }

      return h('div', {
        key,
        class: 'q-tree__node relative-position' +
          ` q-tree__node--${isParent === true ? 'parent' : 'child'}`
      }, [
        h('div', {
          class: 'q-tree__node-header relative-position row no-wrap items-center' +
            (m.link === true ? ' q-tree__node--link q-hoverable q-focusable' : '') +
            (m.selected === true ? ' q-tree__node--selected' : '') +
            (m.disabled === true ? ' q-tree__node--disabled' : ''),
          tabindex: m.link === true ? 0 : -1,
          onClick: (e) => {
            onClick(node, m, e)
          },
          onKeypress (e) {
            if (shouldIgnoreKey(e) !== true) {
              if (e.keyCode === 13) { onClick(node, m, e, true) } else if (e.keyCode === 32) { onExpandClick(node, m, e, true) }
            }
          }
        }, [
          h('div', {
            class: 'q-focus-helper',
            tabindex: -1,
            ref: el => { blurTargets[m.key] = el }
          }),

          m.lazy === 'loading'
            ? h(QSpinner, {
              class: 'q-tree__spinner q-mr-xs',
              color: computedControlColor.value
            })
            : (
              isParent === true
                ? h(QIcon, {
                  class: 'q-tree__arrow q-mr-xs' +
                    (m.expanded === true ? ' q-tree__arrow--rotate' : ''),
                  name: computedIcon.value,
                  onClick (e) { onExpandClick(node, m, e) }
                })
                : null
            ),

          m.hasTicking === true && m.noTick !== true
            ? h(QCheckbox, {
              class: 'q-mr-xs',
              modelValue: m.indeterminate === true ? null : m.ticked,
              color: computedControlColor.value,
              dark: isDark.value,
              dense: true,
              keepColor: true,
              disable: m.tickable !== true,
              onKeydown: stopAndPrevent,
              'onUpdate:modelValue': v => {
                onTickedClick(m, v)
              }
            })
            : null,

          h('div', {
            class: 'q-tree__node-header-content col row no-wrap items-center' +
              (m.selected === true ? selectedColorClass.value : textColorClass.value)
          }, [
            header
              ? header(slotScope)
              : [
                getNodeMedia(node),
                h('div', node[props.labelKey])
              ]
          ])
        ]),

        isParent === true
          ? h(QSlideTransition, {
            duration: props.duration,
            onShow,
            onHide
          }, () => withDirectives(
            h('div', {
              class: 'q-tree__node-collapsible' + textColorClass.value,
              key: `${key}__q`
            }, [
              body,
              h('div', {
                class: 'q-tree__children' +
                (m.disabled === true ? ' q-tree__node--disabled' : '')
              }, children)
            ]),
            [[vShow, m.expanded]]
          ))
          : body
      ])
    }

    function blur (key) {
      const blurTarget = blurTargets[key]
      blurTarget && blurTarget.focus()
    }

    function onClick (node, meta, e, keyboard) {
      keyboard !== true && blur(meta.key)

      if (hasSelection.value) {
        if (meta.selectable) {
          emit('update:selected', meta.key !== props.selected ? meta.key : null)
        }
      } else {
        onExpandClick(node, meta, e, keyboard)
      }

      if (typeof node.handler === 'function') {
        node.handler(node)
      }

      emit('click', node, meta, e, keyboard)
    }

    function onExpandClick (node, meta, e, keyboard) {
      if (e !== undefined) {
        stopAndPrevent(e)
      }
      keyboard !== true && blur(meta.key)
      setExpanded(meta.key, !meta.expanded, node, meta)
    }

    function isMetaTicked (meta) {
      const children = meta.children
      if (children && children.length) {
        return children.every((child) => isMetaTicked(child))
      }
      return meta.ticked
    }

    function setParentsTicked (meta, state) {
      while (meta.parent) {
        const parent = meta.parent
        if (parent.ticked !== state) {
          parent.ticked = state
        }
        meta = parent
      }
    }

    function setChildrenTicked (meta, state) {
      if (meta.children) {
        for (const child of meta.children) {
          if (child.ticked !== state) {
            child.ticked = state
          }
          setChildrenTicked(child, state)
        }
      }
    }

    function setTickedKeys (meta, keys) {
      if (meta) {
        const children = meta.children
        if (children && children.length) {
          if (children.every((child) => isMetaTicked(child))) {
            keys.push(meta.key)
            return
          }
          children.forEach((child) => setTickedKeys(child, keys))
        } else if (meta.ticked) {
          keys.push(meta.key)
        }
      }
    }

    function onTickedClick (nodeMeta, state) {
      if (nodeMeta.indeterminate === true) {
        state = nodeMeta.indeterminateNextState
      }
      if (nodeMeta.strictTicking) {
        setTicked([nodeMeta.key], state)
      } else if (nodeMeta.leafTicking) {
        nodeMeta.ticked = state
        setChildrenTicked(nodeMeta, state)
        if (!state) {
          setParentsTicked(nodeMeta, state)
        }
        const tickedKeys = []
        const nodes = props.nodes
        if (nodes) {
          for (const node of nodes) {
            setTickedKeys(meta.value[node[props.nodeKey]], tickedKeys)
          }
        }
        if (props.ticked !== undefined) {
          emit('update:ticked', tickedKeys)
        } else {
          innerTicked.value = tickedKeys
        }
      }
    }

    // expose public methods
    Object.assign(proxy, {
      getNodeByKey,
      getTickedNodes,
      getExpandedNodes,
      isExpanded,
      collapseAll,
      expandAll,
      setExpanded,
      isTicked,
      setTicked,
      meta: meta.value,
      refMeta: meta
    })

    props.defaultExpandAll === true && expandAll()

    return () => {
      const children = getChildren(props.nodes)

      return h(
        'div', {
          class: classes.value
        },
        children.length === 0
          ? (
            props.filter
              ? props.noResultsLabel || $q.lang.tree.noResults
              : props.noNodesLabel || $q.lang.tree.noNodes
          )
          : children
      )
    }
  }
})
