<template>
  <q-page padding>
    <q-card flat bordered>
      <q-card-section>
        <div class="text-h6">系统配置管理</div>
      </q-card-section>
      <q-separator/>
      <q-card-section>
        <x-split-layout
          dialogTitle="系统配置详情"
          v-model:show-dialog="showFormDialog"
        >
          <template v-slot:before>
            <q-input
              v-model="filterText"
              placeholder="搜索配置"
              dense
              clearable
              outlined
            >
              <template v-slot:append>
                <q-icon name="search"/>
              </template>
              <template v-slot:after>
                <q-btn
                  v-if="$hasAuth('sys-config-create')"
                  icon="add"
                  color="primary"
                  outline
                  @click="toAdd"
                />
              </template>
            </q-input>
            <x-tree
              class="tree q-mt-md"
              ref="treeEle"
              :nodes="nodes"
              :selected="selected"
              v-model:expanded="expanded"
              :filter="filterText"
              node-key="id"
              label-key="configName"
              children-key="children"
              @click="clickNode"
            />
          </template>
          <template v-slot:after>
            <sys-config-form
              ref="formEle"
              :data="form"
              :nodes="nodes"
              :readonly="formReadonly"
            />
          </template>
          <template v-slot:after-bottom>
            <q-btn
              v-if="$hasAuth('sys-config-update') && formReadonly"
              label="编辑"
              color="primary"
              unelevated
              @click="toEdit"
            />
            <q-btn
              v-if="!formReadonly"
              label="保存"
              color="primary"
              unelevated
              @click="save"
            />
            <q-btn
              v-if="!formReadonly"
              label="取消编辑"
              color="warning"
              unelevated
              @click="cancelEdit"
            />
            <q-btn
              v-if="$hasAuth('sys-config-delete')"
              label="删除"
              color="negative"
              unelevated
              @click="remove"
            />
          </template>
        </x-split-layout>
      </q-card-section>
    </q-card>
  </q-page>
</template>

<script>
import { computed, getCurrentInstance, ref, watch } from 'vue'
import { listToMap, listToNodes } from '../../../utils/common-utils'
import { XSplitLayout } from '../../../components/split-layout'
import { XTree } from '../../../components/tree'
import SysConfigForm from './SysConfigForm'

export default {
  components: {
    XTree,
    XSplitLayout,
    SysConfigForm
  },
  setup () {
    const { proxy } = getCurrentInstance()
    const nodes = ref([])
    let nodesMap = {}
    const expanded = ref([])
    const form = ref(null)
    const formReadonly = ref(true)
    const filterText = ref(null)
    const showFormDialog = ref(false)
    const selected = ref(computed(() => form.value ? form.value.id || form.value.pid : null))

    watch(showFormDialog, (value) => {
      if (value === false) {
        cancelEdit()
      }
    })

    async function loadNodes () {
      const list = await proxy.$server.post('/sysConfig/sysConfigList')
      const data = listToNodes(list, 'id', 'pid', 'children')
      nodesMap = listToMap(list, 'id')
      if ((!selected.value && data.length) || !nodesMap[selected.value]) {
        form.value = Object.assign({}, data[0])
      } else {
        form.value = nodesMap[selected.value]
      }
      nodes.value = data
    }

    function expandRoots () {
      for (const item of nodes.value) {
        expanded.value.push(item.id)
      }
    }

    function clickNode (node) {
      form.value = Object.assign({}, node)
      showFormDialog.value = true
    }

    function toAdd () {
      formReadonly.value = false
      form.value = { pid: form.value ? form.value.id : '0' }
      showFormDialog.value = true
    }

    function toEdit () {
      formReadonly.value = false
    }

    function cancelEdit () {
      formReadonly.value = true
      if (selected.value) {
        const node = nodesMap[selected.value]
        if (node) {
          form.value = Object.assign({}, node)
        }
      }
    }

    async function save () {
      const param = await proxy.$refs.formEle.validateAndGetForm()
      delete param.children
      delete param.createTime
      delete param.updateTime
      if (param.id) {
        await proxy.$server.post('/sysConfig/updateSysConfig', param)
      } else {
        form.value.id = await proxy.$server.post('/sysConfig/createSysConfig', param)
      }
      proxy.$message.tipsSuccess('保存成功')
      formReadonly.value = true
      showFormDialog.value = false
      loadNodes()
    }

    async function remove () {
      await proxy.$message.confirm('确定要删除吗？')
      await proxy.$server.post('/sysConfig/deleteSysConfig', { id: form.value.id })
      proxy.$message.tipsSuccess('删除成功')
      formReadonly.value = true
      showFormDialog.value = false
      form.value = null
      loadNodes()
    }

    async function init () {
      await loadNodes()
      expandRoots()
    }

    init()

    return {
      nodes,
      selected,
      expanded,
      form,
      formReadonly,
      filterText,
      showFormDialog,
      clickNode,
      toAdd,
      toEdit,
      cancelEdit,
      save,
      remove
    }
  }
}
</script>
