export function justWatch () {
}

export function toListenerName (event) {
  return `on${event.charAt(0).toUpperCase()}${event.substring(1)}`
}

export function assignProps (target, source, filter) {
  for (const i in source) {
    const value = filter(i, source[i])
    if (value !== undefined) {
      target[i] = value
    }
  }
  return target
}

export function assignEmitListeners (target, events, props, emit) {
  for (const event of events) {
    const listenerName = toListenerName(event)
    target[listenerName] = function () {
      return emit(event, ...arguments)
    }
  }
  return target
}

export function assignSlots (target, slots) {
  for (const name of slots) {
    const slot = slots[name]
    if (slot) {
      target[name] = slot
    }
  }
}
