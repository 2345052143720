import { computed, defineComponent, getCurrentInstance, h, ref } from 'vue'
import { assignEmitListeners, assignProps, justWatch } from '../../utils/vue-utils'
import { QSelect } from 'quasar/src/components/select'
import { QIcon } from 'quasar/src/components/icon'
import { XTree } from '../tree'

const treeProps = {
  nodeKey: { type: String, default: 'value' },
  labelKey: { type: String, default: 'label' },
  childrenKey: { type: String, default: 'children' },
  nodes: { type: Array, default: () => [] },
  defaultExpandAll: { type: Boolean, default: true },
  accordion: Boolean,
  treeIcon: String,
  noNodesLabel: String,
  noResultsLabel: String,
  expanded: Array,
  noConnectors: Boolean,
  strictly: Boolean,
  filterable: Boolean,
  filterMethod: Function
}

export default defineComponent({
  name: 'XTreeSelect',
  props: {
    ...QSelect.props,
    ...treeProps
  },
  emits: [
    ...QSelect.emits,
    'lazy-load'
  ],
  setup (props, { emit, slots }) {
    const instance = getCurrentInstance()

    const filterText = ref('')

    const options = computed(() => nodesToOptions(props.nodes, props.childrenKey))

    const selectProps = computed(() => {
      const result = {}
      assignProps(result, props, (i, v) => treeProps[i] ? undefined : v)
      assignEmitListeners(result, QSelect.emits, instance.vnode.props, emit)
      if (props.filterable) {
        result.useInput = true
        result['onInput-value'] = (value) => {
          filterText.value = value
        }
      }
      Object.assign(result, {
        class: 'x-tree-select',
        ref: 'select',
        options: options.value,
        optionValue: props.nodeKey,
        optionLabel: props.labelKey,
        mapOptions: true,
        disableScroll: true,
        virtualScrollItemSize: 0
      })
      return result
    })

    function nodesToOptions (nodes, childrenKey) {
      if (!nodes || !nodes.length) {
        return []
      }
      const options = []
      for (const node of nodes) {
        options.push(node)
        options.push(...nodesToOptions(node[childrenKey], childrenKey))
      }
      return options
    }

    function getNodeValue (node) {
      return props.emitValue ? node[props.nodeKey] : node
    }

    function isValueEqual (v1, v2) {
      return props.emitValue ? v1 === v2 : (v1 && v1[props.nodeKey]) === (v2 && v2[props.nodeKey])
    }

    function isNodeSelected (node) {
      const nodeVal = getNodeValue(node)
      const selected = props.modelValue
      if (selected && selected.constructor === Array) {
        return selected.find((v) => isValueEqual(v, nodeVal)) !== undefined
      }
      return isValueEqual(selected, nodeVal)
    }

    function toggleNode (node, m) {
      if (instance.refs.tree) {
        instance.refs.tree.setExpanded(m.key, !instance.refs.tree.isExpanded(m.key))
      }
      if (props.strictly || !props.multiple) {
        instance.refs.select.toggleOption(node, true)
        return
      }
      const selected = props.modelValue || []
      const nodeVal = getNodeValue(node)
      if (selected.find((v) => isValueEqual(v, nodeVal))) {
        instance.refs.select.toggleOption(node, true)
        return
      }
      // remove all selected parents
      const tree = instance.refs.tree
      let meta = tree.refMeta.value[node[props.nodeKey]]
      while (meta.parent) {
        const value = getNodeValue(tree.getNodeByKey(meta.parent.key))
        const index = selected.findIndex((v) => isValueEqual(v, value))
        if (index > -1) {
          selected.splice(index, 1)
        }
        meta = meta.parent
      }
      // remove all selected children
      const removeSelectedChildren = (node) => {
        if (node && node.children) {
          for (const child of node.children) {
            const nodeVal = getNodeValue(child)
            const index = selected.findIndex(v => isValueEqual(v, nodeVal))
            if (index > -1) {
              selected.splice(index, 1)
            }
            removeSelectedChildren(child)
          }
        }
      }
      removeSelectedChildren(node)
      instance.refs.select.toggleOption(node, true)
    }

    function render () {
      justWatch(filterText.value)
      return h(QSelect, selectProps.value, {
        ...slots,
        option: renderTreeOptions
      })
    }

    function renderTreeOptions (slot) {
      if (slot.index > 0) {
        return
      }
      const treeProps = {
        class: 'q-pa-sm',
        nodes: props.nodes,
        nodeKey: props.nodeKey,
        labelKey: props.labelKey,
        childrenKey: props.childrenKey,
        defaultExpandAll: props.defaultExpandAll,
        selected: null,
        accordion: props.accordion,
        icon: props.treeIcon,
        noNodesLabel: props.noNodesLabel,
        noResultsLabel: props.noResultsLabel,
        expanded: props.expanded,
        noConnectors: props.noConnectors,
        dark: props.dark,
        filterMethod: props.filterMethod,
        ref: 'tree',
        onClick: (node, m) => toggleNode(node, m),
        onLazyLoad: (e) => emit('lazy-load', e)
      }
      if (props.filterable) {
        treeProps.filter = filterText.value
      }
      return h(XTree, treeProps, {
        'default-header': (slot) => [
          renderNodeMedia(slot.node),
          h('div', {
            class: isNodeSelected(slot.node) ? 'text-primary' : ''
          }, slot.node[props.labelKey])
        ]
      })
    }

    function renderNodeMedia (node) {
      if (node.icon !== undefined) {
        return h(QIcon, {
          class: 'q-tree__icon q-mr-sm',
          name: node.icon,
          color: node.iconColor
        })
      }
      const src = node.img || node.avatar
      if (src) {
        return h('img', {
          class: `q-tree__${node.img ? 'img' : 'avatar'} q-mr-sm`,
          src
        })
      }
    }

    instance.proxy.scrollTo = (index) => {
      if (instance.refs.select) {
        instance.refs.select.scrollTo(index)
      }
    }

    instance.proxy.toggleOption = (node, keepOpen) => {
      if (instance.refs.select) {
        instance.refs.select.toggleOption(node, keepOpen)
      }
    }

    instance.proxy.setExpanded = (key, state) => {
      if (instance.refs.tree) {
        instance.refs.tree.setExpanded(key, state)
      }
    }

    return render
  }
})
